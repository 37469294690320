.ProseMirror {
  padding-bottom: 2rem;
}

.ProseMirror > * + * {
  margin-top: 0.75em;
}
.ProseMirror ul,
.ProseMirror ol {
  padding: 0 1rem;
}
.ProseMirror h1 {
  font-size: 1.3rem;
  font-weight: bold;
}
.ProseMirror h2 {
  font-size: 1.1rem;
  font-weight: bold;
}
.ProseMirror h3 {
  font-size: 1rem;
  font-weight: bold;
}
.ProseMirror a {
  color: rgb(37, 99, 235);
}
.ProseMirror code {
  background-color: rgba(97, 97, 97, 0.1);
}
.ProseMirror pre {
  background: #0d0d0d;
  color: #fff;
  font-family: "JetBrainsMono", monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}
.ProseMirror pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}
.ProseMirror img {
  max-width: 100%;
  height: auto;
}
.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(13, 13, 13, 0.1);
}
.ProseMirror hr {
  border: none;
  border-top: 2px solid rgba(13, 13, 13, 0.1);
  margin: 2rem 0;
}
.ProseMirror ol {
  margin-left: 1rem;
  list-style: decimal;
}
.ProseMirror ul {
  margin-left: 1rem;
  list-style: disc;
}
.ProseMirror hr {
  border-width: 1px;
}
