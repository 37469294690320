.job-post-description h1 {
  padding-bottom: 0.5rem;
  font-size: 1.3rem;
  font-weight: bold;
}

.job-post-description h2 {
  padding-bottom: 0.5rem;
  font-size: 1.1rem;
  font-weight: bold;
}

.job-post-description h3 {
  padding-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
}

.job-post-description p {
  padding-bottom: 1rem;
}

.job-post-description ul,
.job-post-description ol {
  padding-bottom: 1rem;
}

.job-post-description ul li {
  list-style-type: disc;
  margin-left: 1rem;
}

.job-post-description ol li {
  list-style-type: decimal;
  margin-left: 1rem;
}

.job-post-description li p {
  padding: 0;
}
